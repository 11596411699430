import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
// @ts-ignore
import { Slide, Fade } from 'react-reveal';
import ReactPlayer from 'react-player';

const Section = styled.div<{ color?: string }>`
padding: 40px 0;
background-color: ${({ theme, color }) => color ? color : theme.colors.white.base};
box-sizing: border-box;
`;

const SubBannerText = styled.h2`
text-align: center;
color: ${({ theme }) => theme.colors.primaries[300]};
font-size: 25px;
font-weight: 800;
`;

const Container = styled.div`
width: 100%;
margin: 0 auto;
text-align: center;
`;

const SectionTitle = styled.h1<{ color?: string }>`
font-size: 20px;
font-weight: 800;
margin: 0 0 10px 0;
line-height: 1.4em;
${props => props.color && `color: ${props.color}`};
`;

const CircleView = styled.div`
width: 80px;
height: 80px;
border-radius: ${Number.MAX_SAFE_INTEGER}px;
background-color: ${({ theme }) => theme.colors.white.base};
display: flex;
align-items: center;
justify-content: center;
`;

const CircleImage = styled.img`
width: 45px;
height: 45px;
`;

const InstructionLayer = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 50px;
padding: 0 5%;
`;

const InstructionTitle = styled.h3`
color: ${({ theme }) => theme.colors.primaries.base};
font-size: 20px;
font-weight: 700;
margin: 0 0 10px 0;
`;

const SectionImage = styled.img`
width: 80%;
`;

const AppIconLayer = styled.div`
display: flex;
align-items: center;
`;

const AppIconText = styled.span`
color: ${({ theme }) => theme.colors.white.base};
font-size: 25px;
font-weight: 800;
line-height: 20px;
`;

const Link = styled.a`
text-decoration: none;
cursor: pointer;
`;

const SubText = styled.h2<{ color?: string }>`
font-size: 14px;
font-weight: 500;
margin-bottom: 30px;
line-height: 1.4em;
${props => props.color && `color: ${props.color}`};
`;

const VideoWrapper = styled.div`
display: flex;
justify-content: center;
`;

const BadgeText = styled.h2`
color: ${({ theme }) => theme.colors.primaries.base};
font-size: 25px;
font-weight: 800;
`;

const HeaderButtonLayer = styled.div`
margin-top: 10px;
`;

const HeaderText = styled.span`
color: ${({ theme }) => theme.colors.white.base};
font-size: 15px;
font-weight: 700;
line-height: 30px;
`;

const HomeMobile = () => {
    const theme = useContext(ThemeContext);

    return (
        <div>
            <Section
                color={theme.colors.primaries.base}
                style={{ paddingTop: '5%' }}
            >
                <Container>
                    <div style={{ marginBottom: 50, padding: '0 5%' }}>
                        <AppIconLayer style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                                src='images/among_app_icon.png'
                                alt='among_app_icon'
                                style={{ width: 50, height: 50 }}
                            />
                            <AppIconText>AMONG</AppIconText>
                        </AppIconLayer>
                        <HeaderButtonLayer>
                            <a
                                href={'https://docs.google.com/forms/d/1bHwjuLTiEW7HlnsnZ7s4YVPO_rp647GyGfsAENg1-4g'}
                                style={{ cursor: 'pointer', textDecoration: 'none' }}
                                target={'_blank'}
                            >
                                <HeaderText>입점 문의</HeaderText>
                            </a>
                        </HeaderButtonLayer>
                    </div>
                    <Slide top>
                        <SubBannerText>{'반반 PT & 비대면 PT'}</SubBannerText>
                        <SectionTitle
                            color={theme.colors.white.base}
                            style={{ fontSize: 40, marginBottom: 30 }}
                        >운동을 똑똑하게.<br />어몽</SectionTitle>
                    </Slide>
                    <SectionImage src='images/landing_img.png' alt='app_demo' />
                </Container>
                <a
                    href='https://dl.among.world/u9DC'
                    target='_blank'
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        left: -10,
                        right: -10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: theme.colors.primaries[400],
                        padding: 18,
                        boxShadow: '0px 0px 10px -5px #000',
                        zIndex: Number.MAX_SAFE_INTEGER,
                        textDecoration: 'none',
                    }}
                >
                    <div style={{ color: theme.colors.white.base, fontSize: 18 }}>지금 바로 앱 다운로드하기</div>
                </a>
            </Section>
            <Section color={theme.colors.white.base}>
                <Container>
                    <Slide top>
                        <SectionTitle style={{ marginBottom: 30 }}>어몽 이렇게 사용해보세요!</SectionTitle>
                    </Slide>
                    <InstructionLayer>
                        <CircleView>
                            <CircleImage src='images/discounts.png' alt='discounts' />
                        </CircleView>
                        <Fade>
                            <div style={{ marginLeft: 20, textAlign: 'start' }}>
                                <InstructionTitle>합리적인 가격</InstructionTitle>
                                <div>비싼 가격 때문에 망설이고 계신가요?<br />절반 가격으로 PT를 들어보세요.</div>
                            </div>
                        </Fade>
                    </InstructionLayer>
                    <InstructionLayer>
                        <Fade>
                            <div style={{ marginRight: 20, textAlign: 'end' }}>
                                <InstructionTitle>나에게 맞는 수업</InstructionTitle>
                                <div>각 수업의 커리큘럼이<br />정해져있어요. 목적에 맞는<br />수업을 선택해보세요</div>
                            </div>
                        </Fade>
                        <CircleView>
                            <CircleImage src='images/exercise.png' alt='exercise' />
                        </CircleView>
                    </InstructionLayer>
                    <InstructionLayer style={{ margin: 0 }}>
                        <CircleView>
                            <CircleImage src='images/seater-sofa.png' alt='sofa' />
                        </CircleView>
                        <Fade>
                            <div style={{ marginLeft: 20, textAlign: 'start' }}>
                                <InstructionTitle>집에서도 편하게</InstructionTitle>
                                <div>비대면 PT로 수업을 받아보세요.<br />운동 영상만 올리면 끝.</div>
                            </div>
                        </Fade>
                    </InstructionLayer>
                </Container>
            </Section>
            <Section color={`#242424`}>
                <Container>
                    <Slide top>
                        <BadgeText>반반 PT</BadgeText>
                        <SectionTitle color={theme.colors.white.base}>절반 가격으로 PT를 받는 방법</SectionTitle>
                        <SubText
                            color={theme.colors.white.base}
                            style={{ opacity: 0.8 }}
                        >반반PT를 통해서 절반 가격으로 원하는 수업을<br />들어보세요. 같이 들을 사람이 없어도 됩니다.<br />어몽이 모아주니까요 😁</SubText>
                    </Slide>
                    <SectionImage src='images/반반_PT_홈.png' alt='home_demo' />
                </Container>
            </Section>
            <Section color={theme.colors.gray[400]}>
                <Container>
                    <Slide top>
                        <BadgeText>비대면 PT</BadgeText>
                        <SectionTitle>불필요한 이동 없이,<br />어디서든 간편하게 PT를 받는 방법</SectionTitle>
                        <SubText>8900원에 원하는 트레이너에게<br />비대면으로 궁금한 점을 묻고, 레슨을 받아보세요.</SubText>
                    </Slide>
                    <SectionImage src='images/비대면_레슨_사진.png' alt='untact_lesson_demo' />
                </Container>
            </Section>
            <Section color={theme.colors.gray[200]}>
                <Container>
                    <Slide top>
                        <SectionTitle>내 운동의 문제점을 해결해볼까요?</SectionTitle>
                        <SubText>벤치프레스를 하는데 어려움을 겪고<br />계신 회원님 영상</SubText>
                    </Slide>
                    <VideoWrapper>
                        <ReactPlayer
                            width={268}
                            height={480}
                            controls
                            url={[
                                { src: 'videos/sample_video.mp4', type: 'video/mp4' },
                            ]}
                        />
                    </VideoWrapper>
                </Container>
            </Section>
            <Section>
                <Container>
                    <Slide top>
                        <SectionTitle>운동을 똑똑하게, 어몽</SectionTitle>
                    </Slide>
                    <Link
                        href='https://apps.apple.com/kr/app/id1588029542'
                        target='_blank'
                        style={{ marginRight: 20 }}
                    >
                        <img src='images/app_store_button_black.svg' alt='app_store_button_black' style={{ width: 120 }} />
                    </Link>
                    <Link
                        href='https://play.google.com/store/apps/details?id=com.among'
                        target='_blank'
                    >
                        <img src='images/google_play_button_black.svg' alt='google_play_button_black' style={{ width: 120 }} />
                    </Link>
                </Container>
            </Section>
            <Section color={`#242424`} style={{ padding: '20px 0 50px 0' }}>
                <Container>
                    <SubText color={theme.colors.white.base}>
                        <div>(주) 어몽</div>
                        <div>사업자 등록번호: 897-87-01966</div>
                        <div>대표: 류정원 | 서울특별시 성북구 삼양로 22, 202호</div>
                        <div>통신판매업 2021-서울성북-1931</div>
                        <div>호스팅서비스제공자 (주) 어몽</div>
                        <div>Copyright © Among Inc. All Rights Reserved.</div>
                    </SubText>
                </Container>
            </Section>
        </div>
    );
};

export default HomeMobile;