// @ts-ignore
import HomeDesktop from './pages/HomeDesktop';
import { BrowserView, MobileView } from 'react-device-detect';
import HomeMobile from './pages/HomeMobile';

const App = () => {
    return (
        <>
            <BrowserView>
                <HomeDesktop />
            </BrowserView>
            <MobileView>
                <HomeMobile />
            </MobileView>
        </>
    );
};

export default App;