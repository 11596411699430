import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
// @ts-ignore
import { Slide, Fade } from 'react-reveal';
import ReactPlayer from 'react-player/lazy';

const Between = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`;

const HeaderButtonLayer = styled.div`
display: flex;
`;

const HeaderText = styled.span`
color: ${({ theme }) => theme.colors.white.base};
font-size: 20px;
font-weight: 700;
line-height: 30px;
margin-right: 30px;
`;

const SubBannerText = styled.h2`
text-align: left;
color: ${({ theme }) => theme.colors.primaries[300]};
font-size: 40px;
font-weight: 800;
`;

const Section = styled.div<{ color?: string }>`
padding: 100px 0;
background-color: ${({ theme, color }) => color ? color : theme.colors.white.base};
box-sizing: border-box;
`;

const Container = styled.div`
width: 100%;
min-width: 940px;
max-width: 1200px;
margin: 0 auto;
text-align: center;
`;

const SectionTitle = styled.h1<{ color?: string }>`
font-size: 40px;
font-weight: 800;
margin-top: 0;
margin-bottom: 40px;
line-height: 1.4em;
${props => props.color && `color: ${props.color}`};
`;

const CircleView = styled.div`
width: 170px;
height: 170px;
border-radius: ${Number.MAX_SAFE_INTEGER}px;
background-color: ${({ theme }) => theme.colors.white.base};
margin-bottom: 40px;
display: flex;
align-items: center;
justify-content: center;
`;

const CircleImage = styled.img`
width: 100px;
height: 100px;
`;

const InstructionLayer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;

const InstructionTitle = styled.span`
color: ${({ theme }) => theme.colors.primaries.base};
font-size: 25px;
font-weight: 700;
margin-bottom: 30px;
`;

const SectionImage = styled.img`
width: 400px;
`;

const AppIconLayer = styled.div`
display: flex;
align-items: center;
`;

const AppIconText = styled.span`
color: ${({ theme }) => theme.colors.white.base};
font-size: 30px;
font-weight: 800;
line-height: 30px;
`;

const Link = styled.a`
text-decoration: none;
cursor: pointer;
border-radius: 15px;
`;

const SubText = styled.h2<{ color?: string }>`
font-size: 18px;
font-weight: 500;
line-height: 30px;
${props => props.color && `color: ${props.color}`};
`;

const VideoWrapper = styled.div`
display: flex;
justify-content: center;
`;

const BadgeText = styled.h2`
color: ${({ theme }) => theme.colors.primaries.base};
font-size: 30px;
font-weight: 800;
`;

const AppDownloadImage = styled.img`
border-radius: 16px;
transition: background-color 0.2s ease;

:hover {
    background-color: #ffffff80;
}
`;

const FooterAppDownloadImage = styled(AppDownloadImage)`
border-radius: 10px;

:hover {
    background-color: #00000020;
}
`;

const HomeDesktop = () => {
    const theme = useContext(ThemeContext);

    return (
        <div>
            <Section
                color={theme.colors.primaries.base}
                style={{ paddingTop: 30, height: '100vh' }}
            >
                <Container>
                    <div style={{ marginBottom: 50, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <AppIconLayer style={{ display: 'flex', alignItems: 'center' }}>
                            <img src='images/among_app_icon.png' alt='among_app_icon' style={{ width: 60, height: 60 }} />
                            <AppIconText>AMONG</AppIconText>
                        </AppIconLayer>
                        <HeaderButtonLayer>
                            <a
                                href={'https://docs.google.com/forms/d/1bHwjuLTiEW7HlnsnZ7s4YVPO_rp647GyGfsAENg1-4g'}
                                style={{ cursor: 'pointer', textDecoration: 'none' }}
                                target={'_blank'}
                            >
                                <HeaderText>입점 문의</HeaderText>
                            </a>
                        </HeaderButtonLayer>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Fade>
                            <div>
                                <SubBannerText>{'반반 PT & 비대면 PT'}</SubBannerText>
                                <SectionTitle
                                    color={theme.colors.white.base}
                                    style={{ fontSize: 80, textAlign: 'start', marginRight: 60 }}
                                >운동을 똑똑하게.<br />어몽</SectionTitle>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <Link
                                        href='https://apps.apple.com/kr/app/id1588029542'
                                        target='_blank'
                                        style={{ marginRight: 20 }}
                                    >
                                        <AppDownloadImage src='images/app_store_button.svg' alt='app_store_button' style={{ width: 200 }} />
                                    </Link>
                                    <Link
                                        href='https://play.google.com/store/apps/details?id=com.among'
                                        target='_blank'
                                    >
                                        <AppDownloadImage src='images/google_play_button.svg' alt='google_play_button' style={{ width: 200 }} />
                                    </Link>
                                </div>
                            </div>
                        </Fade>
                        <Slide bottom>
                            <SectionImage
                                src='images/landing_img.png'
                                alt='app_demo'
                            />
                        </Slide>
                    </div>
                </Container>
            </Section>
            <Section color={theme.colors.white.base}>
                <Container>
                    <Fade>
                        <SectionTitle>어몽 이렇게 사용해보세요!</SectionTitle>
                        <Between
                            style={{ justifyContent: 'space-evenly' }}
                        >
                            <InstructionLayer>
                                <CircleView>
                                    <CircleImage src='images/discounts.png' alt='discounts' />
                                </CircleView>
                                <InstructionTitle>합리적인 가격</InstructionTitle>
                                <div>비싼 가격 때문에 망설이고 계신가요?<br />절반 가격으로 PT를 들어보세요.</div>
                            </InstructionLayer>
                            <InstructionLayer>
                                <CircleView>
                                    <CircleImage src='images/exercise.png' alt='exercise' />
                                </CircleView>
                                <InstructionTitle>나에게 맞는 수업</InstructionTitle>
                                <div>각 수업의 커리큘럼이 정해져있어요.<br />목적에 맞는 수업을 선택해보세요.</div>
                            </InstructionLayer>
                            <InstructionLayer>
                                <CircleView>
                                    <CircleImage src='images/seater-sofa.png' alt='sofa' />
                                </CircleView>
                                <InstructionTitle>집에서도 편하게</InstructionTitle>
                                <div>비대면 PT로 수업을 받아보세요.<br />운동 영상만 올리면 끝.</div>
                            </InstructionLayer>
                        </Between>
                    </Fade>
                </Container>
            </Section>
            <Section color={`#242424`}>
                <Container>
                    <Between>
                        <Slide bottom>
                            <div style={{ flex: 1, marginRight: 40 }}>
                                <SectionImage src='images/반반_PT_홈.png' alt='home_demo' />
                            </div>
                        </Slide>
                        <Fade>
                            <div style={{ textAlign: 'start', flex: 1 }}>
                                <BadgeText>반반 PT</BadgeText>
                                <SectionTitle color={theme.colors.white.base}>절반 가격으로<br />PT를 받는 방법</SectionTitle>
                                <SubText color={theme.colors.white.base} style={{ opacity: 0.8 }}>반반PT를 통해서 절반 가격으로 원하는 수업을 들어보세요.<br />나와 비슷한 수준과 목표를 가진 사람과 둘이서 레슨을 듣는 수업이에요.<br />같이 들을 사람이 없어도 됩니다. 어몽이 모아주니까요 😁</SubText>
                            </div>
                        </Fade>
                    </Between>
                </Container>
            </Section>
            <Section color={theme.colors.gray[400]}>
                <Container>
                    <Between>
                        <Fade>
                            <div style={{ textAlign: 'end', flex: 1, marginRight: 40 }}>
                                <BadgeText>비대면 PT</BadgeText>
                                <SectionTitle>불필요한 이동 없이<br />어디서든 간편하게 PT를 받는 방법</SectionTitle>
                                <SubText>8900원에 원하는 트레이너에게<br />비대면으로 궁금한 점을 묻고, 레슨을 받아보세요.</SubText>
                            </div>
                        </Fade>
                        <Slide bottom>
                            <div style={{ flex: 1 }}>
                                <SectionImage src='images/비대면_레슨_사진.png' alt='untact_lesson_demo' />
                            </div>
                        </Slide>
                    </Between>
                </Container>
            </Section>
            <Section color={theme.colors.gray[200]}>
                <Fade>
                    <Container>
                        <SectionTitle>내 운동의 문제점을 해결해볼까요?</SectionTitle>
                        <SubText>벤치프레스를 하는데 어려움을 겪고<br />계신 회원님 영상</SubText>
                    </Container>
                </Fade>
                <VideoWrapper>
                    <ReactPlayer
                        width={268}
                        height={480}
                        controls
                        url={[
                            { src: 'videos/sample_video.mp4', type: 'video/mp4' },
                        ]}
                    />
                </VideoWrapper>
            </Section>
            <Section>
                <Fade>
                    <Container>
                        <SectionTitle>운동을 똑똑하게, 어몽</SectionTitle>
                        <Link
                            href='https://apps.apple.com/kr/app/id1588029542'
                            target='_blank'
                            style={{ marginRight: 20 }}
                        >
                            <FooterAppDownloadImage src='images/app_store_button_black.svg' alt='app_store_button_black' style={{ width: 150, borderRadius: 10 }} />
                        </Link>
                        <Link
                            href='https://play.google.com/store/apps/details?id=com.among'
                            target='_blank'
                        >
                            <FooterAppDownloadImage src='images/google_play_button_black.svg' alt='google_play_button_black' style={{ width: 150, borderRadius: 10 }} />
                        </Link>
                    </Container>
                </Fade>
            </Section>
            <Section color={`#242c2a`} style={{ padding: '40px 0' }}>
                <Container>
                    <div style={{ color: theme.colors.white.base, fontSize: 14 }}>
                        <div>(주) 어몽</div>
                        <div>사업자 등록번호: 897-87-01966 | 대표: 류정원 | 서울특별시 성북구 삼양로 22, 202호</div>
                        <div>통신판매업 2021-서울성북-1931 | 호스팅서비스제공자 (주) 어몽</div>
                        <div>Copyright © Among Inc. All Rights Reserved.</div>
                    </div>
                </Container>
            </Section>
        </div>
    );
};

export default HomeDesktop;