import { createGlobalStyle } from 'styled-components';
import { isMobile } from 'react-device-detect';

const GlobalStyle = createGlobalStyle`
    * {
        font-family: 'NanumSquareRound', sans-serif!important;
    }
    body {
        padding: 0;
        margin: 0;
        font-size: ${isMobile ? 14 : 18}px;
        line-height: 1.4em;
    };
`;

export default GlobalStyle;